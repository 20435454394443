import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import WhatsAppIcon from "../../assets/img/whatsapp.png";
import Logo from "../../assets/img/logo.jpg";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const handleClick = () => {
    const phoneNumber = '5548991899219';
    const message = 'Olá, estou entrando em contato!';

    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(url, '_blank');
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={Logo} style={{ width: "45px" }} alt="office" className="radius6" />
              <h1 style={{ marginLeft: "6px", marginTop: "5px" }} className="font20 extraBold">
                PREMIER PISOS INDUSTRIAIS
              </h1>
            </div>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px", fontFamily: 'Montserrat sans-serif' }} to="home" spy={true} smooth={true} offset={-80}>
                A EMPRESA
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px", fontFamily: 'Montserrat sans-serif' }} to="clientes" spy={true} smooth={true} offset={-80}>
                CLIENTES
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px", fontFamily: 'Montserrat sans-serif' }} to="projects" spy={true} smooth={true} offset={-80}>
                SERVIÇOS
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px", fontFamily: 'Montserrat sans-serif' }} to="contact" spy={true} smooth={true} offset={-80}>
                CONTATO
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15" style={{ marginRight: "25px", height: "20px" }}>
              (48) 3257-3497
            </li>
            <li className="semiBold font15" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={WhatsAppIcon} onClick={handleClick} alt="whatsapp" style={{ width: "20px", marginRight: "6px", cursor: "pointer" }} />
              <span style={{ height: "20px", cursor: "pointer" }} onClick={handleClick}>
                (48) 99189-9219
              </span>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


