import React from "react";
import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TratamentoJuntas from "../../assets/img/servicos/tratamentos de juntas/principal.jpg";
import TJ1 from "../../assets/img/servicos/tratamentos de juntas/1.jpg";
import TJ2 from "../../assets/img/servicos/tratamentos de juntas/2.jpg";
import TJ3 from "../../assets/img/servicos/tratamentos de juntas/3.jpg";
import AutoNivelante from "../../assets/img/servicos/Autonivelante/principal.jpg";
import AN1 from "../../assets/img/servicos/Autonivelante/1.jpg";
import AN2 from "../../assets/img/servicos/Autonivelante/2.jpg";
import ConcretoEstampado from "../../assets/img/servicos/concreto estampado/principal.jpg";
import CE1 from "../../assets/img/servicos/concreto estampado/1.jpg";
import CE2 from "../../assets/img/servicos/concreto estampado/2.jpg";
import MicroCimento from "../../assets/img/servicos/Micro cimento/principal.jpg";
import MC1 from "../../assets/img/servicos/Micro cimento/1.jpg";
import MC2 from "../../assets/img/servicos/Micro cimento/2.jpg";
import MC3 from "../../assets/img/servicos/Micro cimento/3.jpg";
import MC4 from "../../assets/img/servicos/Micro cimento/4.jpg";
import MC5 from "../../assets/img/servicos/Micro cimento/5.jpg";
import MC6 from "../../assets/img/servicos/Micro cimento/6.jpg";
import MC7 from "../../assets/img/servicos/Micro cimento/7.jpg";
import MC8 from "../../assets/img/servicos/Micro cimento/8.jpg";
import MontagemArmadura from "../../assets/img/servicos/Montagem da Armadura/principal.jpg";
import MA1 from "../../assets/img/servicos/Montagem da Armadura/1.jpg";
import MA2 from "../../assets/img/servicos/Montagem da Armadura/2.jpg";
import MA6 from "../../assets/img/servicos/Montagem da Armadura/6.jpg";
import MA7 from "../../assets/img/servicos/Montagem da Armadura/7.jpg";
import MA8 from "../../assets/img/servicos/Montagem da Armadura/8.jpg";
import MA9 from "../../assets/img/servicos/Montagem da Armadura/9.jpg";
import MA10 from "../../assets/img/servicos/Montagem da Armadura/10.jpg";
import MA11 from "../../assets/img/servicos/Montagem da Armadura/11.jpg";
import MA12 from "../../assets/img/servicos/Montagem da Armadura/12.jpg";
import MA13 from "../../assets/img/servicos/Montagem da Armadura/13.jpg";
import MA14 from "../../assets/img/servicos/Montagem da Armadura/14.jpg";
import MA15 from "../../assets/img/servicos/Montagem da Armadura/15.jpg";

import GranilhaDrenante from "../../assets/img/servicos/Granilha Drenante/principal.jpg";
import GD1 from "../../assets/img/servicos/Granilha Drenante/1.jpg";
import GD2 from "../../assets/img/servicos/Granilha Drenante/2.jpg";
import GD3 from "../../assets/img/servicos/Granilha Drenante/3.jpg";
import GD4 from "../../assets/img/servicos/Granilha Drenante/4.jpg";
import GD5 from "../../assets/img/servicos/Granilha Drenante/5.jpg";
import GD6 from "../../assets/img/servicos/Granilha Drenante/6.jpg";
import GD7 from "../../assets/img/servicos/Granilha Drenante/7.jpg";
import GD8 from "../../assets/img/servicos/Granilha Drenante/8.jpg";
import GD9 from "../../assets/img/servicos/Granilha Drenante/9.jpg";
import GD10 from "../../assets/img/servicos/Granilha Drenante/10.jpg";
import GD11 from "../../assets/img/servicos/Granilha Drenante/11.jpg";

import RevestimentoFulget from "../../assets/img/servicos/Revestimento Fulget/principal.jpg";
import RF1 from "../../assets/img/servicos/Revestimento Fulget/1.jpg";
import RF2 from "../../assets/img/servicos/Revestimento Fulget/2.jpg";
import RF3 from "../../assets/img/servicos/Revestimento Fulget/3.jpg";

import PinturaEpoxi from "../../assets/img/servicos/Pintura Epóxi/principal.jpg";
import PE1 from "../../assets/img/servicos/Pintura Epóxi/1.jpg";
import PE2 from "../../assets/img/servicos/Pintura Epóxi/2.jpg";
import PE3 from "../../assets/img/servicos/Pintura Epóxi/3.jpg";
import PE4 from "../../assets/img/servicos/Pintura Epóxi/4.jpg";
import PE5 from "../../assets/img/servicos/Pintura Epóxi/5.jpg";
import PE6 from "../../assets/img/servicos/Pintura Epóxi/6.jpg";
import PE7 from "../../assets/img/servicos/Pintura Epóxi/7.jpg";
import PE8 from "../../assets/img/servicos/Pintura Epóxi/8.jpg";
import PE9 from "../../assets/img/servicos/Pintura Epóxi/9.jpg";
import PE10 from "../../assets/img/servicos/Pintura Epóxi/10.jpg";
import PE11 from "../../assets/img/servicos/Pintura Epóxi/11.jpg";
import PE12 from "../../assets/img/servicos/Pintura Epóxi/12.jpg";
import PE13 from "../../assets/img/servicos/Pintura Epóxi/13.jpg";
import PE14 from "../../assets/img/servicos/Pintura Epóxi/14.jpg";
import PE15 from "../../assets/img/servicos/Pintura Epóxi/15.jpg";
import PE16 from "../../assets/img/servicos/Pintura Epóxi/16.jpg";
import PE17 from "../../assets/img/servicos/Pintura Epóxi/17.jpg";
import PE18 from "../../assets/img/servicos/Pintura Epóxi/18.jpg";
import PE19 from "../../assets/img/servicos/Pintura Epóxi/19.jpg";
import PE20 from "../../assets/img/servicos/Pintura Epóxi/20.jpg";
import PE21 from "../../assets/img/servicos/Pintura Epóxi/21.jpg";

import NivelamentoLaser from "../../assets/img/servicos/Nivelamento a laser/principal.jpg";
import NL1 from "../../assets/img/servicos/Nivelamento a laser/1.jpg";
import NL2 from "../../assets/img/servicos/Nivelamento a laser/2.jpg";

import PisoPolido from "../../assets/img/servicos/Piso Polido/principal.jpg";
import PP1 from "../../assets/img/servicos/Piso Polido/1.jpg";
import PP2 from "../../assets/img/servicos/Piso Polido/2.jpg";
import PP3 from "../../assets/img/servicos/Piso Polido/3.jpg";
import PP4 from "../../assets/img/servicos/Piso Polido/4.jpg";
import PP5 from "../../assets/img/servicos/Piso Polido/5.jpg";
import PP6 from "../../assets/img/servicos/Piso Polido/6.jpg";
import PP7 from "../../assets/img/servicos/Piso Polido/7.jpg";
import PP8 from "../../assets/img/servicos/Piso Polido/8.jpg";
import PP9 from "../../assets/img/servicos/Piso Polido/9.jpg";
import PP10 from "../../assets/img/servicos/Piso Polido/10.jpg";
import PP11 from "../../assets/img/servicos/Piso Polido/11.jpg";
import PP12 from "../../assets/img/servicos/Piso Polido/12.jpg";
import PP13 from "../../assets/img/servicos/Piso Polido/13.jpg";
import PP14 from "../../assets/img/servicos/Piso Polido/14.jpg";
import PP15 from "../../assets/img/servicos/Piso Polido/15.jpg";

import PisoVassourado from "../../assets/img/servicos/Piso Vassourado/principal.jpg";
import PV1 from "../../assets/img/servicos/Piso Vassourado/1.jpg";
import PV2 from "../../assets/img/servicos/Piso Vassourado/2.jpg";
import PV3 from "../../assets/img/servicos/Piso Vassourado/3.jpg";

import Lapidacao from "../../assets/img/servicos/Lapidacao/principal.jpg";
import L1 from "../../assets/img/servicos/Lapidacao/1.jpg";
import L2 from "../../assets/img/servicos/Lapidacao/2.jpg";
import L3 from "../../assets/img/servicos/Lapidacao/3.jpg";
import L4 from "../../assets/img/servicos/Lapidacao/4.jpg";
import L5 from "../../assets/img/servicos/Lapidacao/5.jpg";
import L6 from "../../assets/img/servicos/Lapidacao/6.jpg";
import L7 from "../../assets/img/servicos/Lapidacao/7.jpeg";
import L8 from "../../assets/img/servicos/Lapidacao/8.jpeg";
import L9 from "../../assets/img/servicos/Lapidacao/9.jpeg";

import TelaFibraVidro from "../../assets/img/produtos/tela de fibra de vidro.jpg";
import BarraTransferencia from "../../assets/img/produtos/barra de transferencia.jpg";
import Geogrelhas from "../../assets/img/produtos/geogrelhas.png";
import EndurecedorSuperficie from "../../assets/img/produtos/endurecedor superficie.jpg";
import CuraQuimica from "../../assets/img/produtos/cura quimica.jpeg";

import ProjectBox from "../Elements/ProjectBox";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Projects() {
  const imgEstampado = [
    { img: CE1 }, { img: CE2 }
  ];

  const imgTratamento = [
    { img: TJ1 }, { img: TJ2 }, { img: TJ3 }
  ];

  const imgAuto = [
    { img: AN1 }, { img: AN2 }
  ];

  const imgMicroCimento = [
    { img: MC1 }, { img: MC2 }, { img: MC3 }, { img: MC4 }, { img: MC5 }, { img: MC6 }, { img: MC7 }, { img: MC8 }
  ];

  const imgMicroArmadura = [
    { img: MA1 }, { img: MA2 }, { img: MA6 }, { img: MA7 }, { img: MA8 }, { img: MA9 }, { img: MA10 },
    { img: MA11 }, { img: MA12 }, { img: MA13 }, { img: MA14 }, { img: MA15 }
  ];

  const imgGranilhaDrenante = [
    { img: GD1 }, { img: GD2 }, { img: GD3 }, { img: GD4 }, { img: GD5 }, { img: GD6 }, { img: GD7 }, { img: GD8 }, { img: GD9 }, { img: GD10 },
    { img: GD11 }
  ];

  const imgRevestimentoFulget = [
    { img: RF1 }, { img: RF2 }, { img: RF3 }
  ];


  const imgPinturaEpoxi = [
    { img: PE1 }, { img: PE2 }, { img: PE3 }, { img: PE4 }, { img: PE5 }, { img: PE6 }, { img: PE7 }, { img: PE8 }, { img: PE9 }, { img: PE10 },
    { img: PE11 }, { img: PE12 }, { img: PE13 }, { img: PE14 }, { img: PE15 }, { img: PE16 }, { img: PE17 }, { img: PE18 }, { img: PE19 }, { img: PE20 },
    { img: PE21 }
  ];

  const imgNivelamentoLaser = [
    { img: NL1 }, { img: NL2 }
  ];

  const imgPisoPolido = [
    { img: PP1 }, { img: PP2 }, { img: PP3 }, { img: PP4 }, { img: PP5 }, { img: PP6 }, { img: PP7 }, { img: PP8 }, { img: PP9 }, { img: PP10 },
    { img: PP11 }, { img: PP12 }, { img: PP13 }, { img: PP14 }, { img: PP15 }
  ];

  const imgPisoVassourado = [
    { img: PV1 }, { img: PV2 }, { img: PV3 }
  ];

  const imgLapidacao = [
    { img: L1 }, { img: L2 }, { img: L3 }, { img: L4 }, { img: L5 }, { img: L6 }, { img: L7 }, { img: L8 }, { img: L9 }
  ];


  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">

          <Box style={{ marginTop: '50px' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab style={{ fontFamily: "'Khula', sans-serif", textTransform: "capitalize", fontWeight: "800!important" }} label="Serviços" {...a11yProps(0)} />
              <Tab style={{ fontFamily: "'Khula', sans-serif", textTransform: "capitalize", fontWeight: "800!important" }} label="Produtos" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgPisoPolido}
                    img={PisoPolido}
                    title="Piso Polido"
                    titleModal="Piso Polido"
                    textModal="Sendo bastante utilizado para pavilhões de uso em geral, estacionamentos, fábricas, indústrias, oficinas, entre outras áreas, o piso polido é ideal para pavimentos que necessitam aguentar grande peso. Além de oferecer alta resistência e durabilidade, possui qualidade estética natural e também permite que sejam aplicados diversos produtos para deixá-lo com outros atributos. Este tipo de piso tem como características impermeabilidade, resistência mecânica, resistência à corrosão, e vida útil prolongada, o que representa para o cliente maior economia e ótimo custo-benefício."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgNivelamentoLaser}
                    img={NivelamentoLaser}
                    title="Nivelamento a laser"
                    titleModal="Nivelamento a laser"
                    textModal="O nivelamento a laser é utilizado em todas as etapas construtivas, desde  a preparação da  base superficial, proporcional um maior aproveitamento e diminuindo o desperdício do concreto, até na própria execução do piso de concreto para deixar na altura final desejada, trazendo maior estabilidade para o piso gerando menos pontos de fissurada devido a variedades de espessura."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgMicroArmadura}
                    img={MontagemArmadura}
                    title="Montagem da Armadura​"
                    titleModal="Montagem da Armadura​"
                    textModal=" A montagem correta das armaduras de distribuição é de uma gigante importância na hora da preparação final para o recebimento da camada de cobrimento, pois, junto a espessura de concreto, a capacidade de carga é gerada proporcionando um revestimento de alto desempenho ideal. Sempre obedecendo os projetos." />
                </div>
              </div>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    img={TratamentoJuntas}
                    items={imgTratamento}
                    title="Tratamentos de Juntas"
                    titleModal="Tratamentos de Juntas"
                    textModal="Os mais conhecidos para pisos de concreto são: tratamento de juntas de dilatação, Juntas serradas, juntas de encontro, tratamento e recuperação de Bordas (lábios poliméricos e lábios elastoméricos). As juntas de dilatação no piso de concreto preenchem os espaços entre as estruturas, a fim de evitar a infiltração de produto ou material sólido que possa comprometer a movimentação da estrutura, acarretando em rachaduras. Para que a junta de dilatação não seja danificada por nenhum tipo de movimentação, o tratamento de Juntas é realizado através de técnicas, materiais e processos diversos."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgPisoVassourado}
                    img={PisoVassourado}
                    title="Piso Texturado"
                    titleModal="Piso Texturado"
                    textModal="O concreto estampado é um pavimento de concreto monolítico, executado “in loco”, que consiste em um sistema de textura ”marcas de rolo de textura” ou vassourado “marcas de linhas”, que reproduz um desenho no piso no mesmo instante em que é feita a sua concretagem, proporcionando maior resistência à abrasão e ao atrito já que ambos os serviços são antiderrapantes, porém, trazendo visuais modernos e esteticamente bonitos."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgEstampado}
                    img={ConcretoEstampado}
                    title="Concreto Estampado"
                    titleModal="Concreto Estampado"
                    textModal="O concreto estampado é um pavimento de concreto monolítico, executado “in loco”, que consiste em um sistema de impressão, que reproduz um desenho no piso no mesmo instante em que é feita a sua concretagem, proporcionando maior resistência à abrasão, ao atrito e a manchas de óleos. Incorpora beleza, durabilidade e facilidade de manutenção, além de reproduzir o charme de outros tipos de pisos, como pedras, tijolos, cerâmicas, blocos, madeiras etc."
                  />
                </div>
              </div>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgGranilhaDrenante}
                    img={GranilhaDrenante}
                    title="Granilhas Drenantes​"
                    titleModal="Granilhas Drenantes​"
                    textModal="O piso em granilha drenante é um revestimento com variação de cores, desenhos e granulometria, no qual as granilhas irão lhe proporcionar o que tem de mais moderno em termos de pisos monoliticos e resistentes no mercado. Tem um alto nível de refletância solar, ótima permeabilidade e podem ser usados tanto em áreas externas quanto em ambientes internos que fiquem expostos à água, pois devido a sua capacidade de permeabilidade, a água, quando em contato com este revestimento, consegue passar para o solo com grande facilidade." />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    img={MicroCimento}
                    items={imgMicroCimento}
                    title="Micro cimento"
                    titleModal="Micro cimento"
                    textModal="O micro cimento é um revestimento monolítico à base de cimento e polímeros de alta tecnologia, cuja aplicação proporciona alta performance ao revestimento, dureza, cor e durabilidade, criando uma superfície contínua sem juntas de dilatação, resistente e com vários acabamentos finais.
                    Por ser um revestimento decorativo versátil e moderno, se tornou cada vez mais popular nos últimos anos, oferecendo uma vasta gama de possibilidades e acabamentos e ambientes com caráter único e pessoal."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgPinturaEpoxi}
                    img={PinturaEpoxi}
                    title="Pintura Epóxi"
                    titleModal="Pintura Epóxi"
                    textModal="Os diferenciais de uma tinta epóxi são sua alta resistência à umidade e produtos químicos, alta durabilidade e resistência à abrasão. Além de ter uma ótima direção aos mais diversos tipos de superfícies, é fácil de limpar e fazer sua manutenção. Muito utilizados em diversas áreas como, garagens, estacionamentos de shoppings, casas e galpões, a pintura epóxi sela o piso de forma uniforme e sem emendas aparentes, podendo ser com acabamento brilhante, semi-brilho ou fosco, com uma proposta moderna e a estética agradável."
                  />
                </div>
              </div>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgLapidacao}
                    img={Lapidacao}
                    title="Lapidação​"
                    titleModal="Lapidação​"
                    textModal="Esta técnica é um processo de tratamento da superfície de concreto que produz um brilho natural, esteticamente agradável, aumentando a durabilidade da superfície. A lapidação de concreto permite a renovação de superfícies antigas ou desgastadas, torna-o mais denso e resistente ao desgaste por abrasão e riscos, proporciona beleza através do brilho e aparência dos agregados que compõem o concreto, facilita a limpeza, pois a porosidade é fechada, e a utilização de endurecedores e seladores de superfície." />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgRevestimentoFulget}
                    img={RevestimentoFulget}
                    title="Revestimento Fulget"
                    titleModal="Revestimento Fulget"
                    textModal="O piso fulget é um revestimento composto por pequenas pedras naturais, cimento e aditivo, no qual as pedras ficam aparentes, conferindo um design natural, rústico e elegante.
                    Este revestimento apresenta uma textura naturalmente áspera, proporcionando característica antiderrapante e permeabilidade à superfície. Tais fatos tornam o piso fulget bastante resistente à ação da umidade e do calor, o que impede a degradação do revestimento mesmo quando exposto às ações do tempo. Além disso, possui propriedade atérmica, não absorvendo calor mesmo se exposto diretamente ao sol.
                    Como há uma grande variedade de tipos e cores de pedrinhas que irão compor o fulget, é possível aplicá-lo em diferentes projetos de decoração, tendo como resultado uma aparência única em todas as propostas."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={imgAuto}
                    img={AutoNivelante}
                    title="Contrapiso Autonivelante​"
                    titleModal="Contrapiso Autonivelante​"
                    textModal="O contrapiso autonivelante apresenta como principal característica a fluidez, ou seja, tem a capacidade de se espalhar naturalmente na área de aplicação. O contrapiso autonivelante serve para nivelar e recuperar bases de concreto, como pisos e contrapisos, de forma prática, produtiva e econômica." />
                </div>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={[]}
                    img={TelaFibraVidro}
                    title="Tela de Fibra de Vidro​"
                    titleModal="Tela de Fibra de Vidro​"
                    textModal="As Telas de Polímeros Reforçados com Fibras de Vidro (GFRP) são produzidos pelo processo de pultrusão de fibras e resinas epóxis (matriz polimérica), com fórmula patenteada pelo fabricante.
                    ​​As Telas têm como característica serem trançadas de maneira que seja impossível desconectar os elementos que a compõem, mantendo o espaçamento e a quantidade de elementos de maneira estável até o limite de tensão de ruptura da fibra. Outras características são: Resistência à corrosão e a substâncias químicas, alta resistência à tração, alta resistência à fadiga, baixa condutividade térmica, não conduz eletricidade, não tóxico, muito leve, elevada durabilidade e preços competitivos."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={[]}
                    img={BarraTransferencia}
                    title="Barras de Transferência"
                    titleModal="Barras de Transferência"
                    textModal="Feitas com polímeros reforçados com fibra de vidro, as barras de transferencia são ideias como dispositivos de transferencia de carga em pavimentos rígidos de longa vida útil como rodovias e pisos industriais
                    Utilizadasnas juntas de pisos de concreto, elas transmitem as cargas verticais de uma placa para a outra e também permitem os movimentos horizontais de expansão de retração, decorrentes da variação de temperatura. Algumas vantagens da barra de fibra de vidro são: não precisa pintar, não enferruja nem oxida, extremamente leve, menor risco de acidente, maior durabilidade, pouca manutenção e menor custo final."
                  />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={[]}
                    img={Geogrelhas}
                    title="Geogrelhas"
                    titleModal="Geogrelhas"
                    textModal="Malhas flexíveis de polímeros reforçados com fibras de vidro e/ou de basalto (BFRP/GFRP) –, para reforço na construção ou recuperação de rodovias existentes, anteriormente construídas em pavimento rígido ou flexível. são materiais compósitos, nos quais as fibras são utilizadas como componentes de reforço. São de duas vezes e meia a quatro vezes mais resistentes que as malhas de aço. Não são suscetíveis à degradação e à oxidação em ambientes ácidos e alcalinos. Não se desestruturam ou decompõem em altas temperaturas, podem manter a elasticidade (alto módulo) em baixas temperaturas, tem baixa deformação.
                    Estabilizar a base, reforçando as estruturas do solo durante a construção de rodovias, ferrovias, estruturas hidráulicas, tubulações, aterros para eliminação de resíduos, reforço de fundações, proteção contra erosão de aterros e fundações usando solo com vegetais e plantio de grama. Fortalecer estruturas de contenção e encostas, fazendo o reforço do solo.
                    Eliminar as falhas na base do solo."
                  />
                </div>
              </div>

              <div className="row textCenter">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={[]}
                    img={EndurecedorSuperficie}
                    title="Endurecedor de Superfície​"
                    titleModal="Endurecedor de Superfície​"
                    textModal="As Telas de Polímeros Reforçados com Fibras de Vidro (GFRP) são produzidos pelo processo de pultrusão de fibras e resinas epóxis (matriz polimérica), com fórmula patenteada pelo fabricante.
                    ​​As Telas têm como característica serem trançadas de maneira que seja impossível desconectar os elementos que a compõem, mantendo o espaçamento e a quantidade de elementos de maneira estável até o limite de tensão de ruptura da fibra. Outras características são: Resistência à corrosão e a substâncias químicas, alta resistência à tração, alta resistência à fadiga, baixa condutividade térmica, não conduz eletricidade, não tóxico, muito leve, elevada durabilidade e preços competitivos."/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    items={[]}
                    img={CuraQuimica}
                    title="Cura Química​"
                    titleModal="Cura Química​​"
                    textModal="A cura química é um agente de cura aplicado no piso concreto, sobre o qual forma uma película protetora não aderente e transparente, cuja finalidade é selar o concreto e permitir a cura adequada do mesmo. A cura química evita a evaporação rápida da água, protegendo o concreto fresco contra a desidratação precoce provocada pelo calor e pelo vento, e assim impedindo a diminuição da resistência, formação de trincas e fissuras." />
                </div>
              </div>
            </TabPanel>

          </Box>

        </div>
      </div>
    </Wrapper>
  );
}


const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Wrapper = styled.div`

.MuiTab-root {
  font-weight: 800 !important;
  font-size: 2.5rem;
  margin-top: 50px;
  margin: 0;
  padding: 0;
  color: #0e0d36;
  font-family: 'Khula', sans-serif;
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  .MuiTab-root {
    font-size: 1.5rem !important;
  }
  
  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: center;
  }
}


`;