import * as React from 'react';
import styled from "styled-components";
// Assets
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FullButton from "../Buttons/FullButton";
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import ModalHelp from "../../components/Elements/ModalHelp";

export default function PricingTable({ iconSimulacao, title, text }) {
  const [query, setQuery] = React.useState('idle');
  const [qtdTelas, setQtdTelas] = React.useState("0");
  const [area, setArea] = React.useState(0);
  const [tipoTela, setTipoTela] = React.useState('');

  const handleTipoTela = (event) => {
    setTipoTela(event.target.value);
  };

  const handleArea = (event) => {
    console.log(event.target.value);
    setArea(event.target.value);
  };

  let getIcon;

  switch (iconSimulacao) {
    case "browser":
      getIcon = <BrowserIcon />;
      break;
  }

  const handleClickQuery = () => {
    setQuery('progress');
    window.setTimeout(() => {
      let unidadeTelas = area / tipoTela;

      setQtdTelas(unidadeTelas.toFixed(2));
      setQuery('success');
    }, 3000);
  };

  return (
    (query != 'progress') ? (<Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">

      </div>
      <div className='font30 extraBold' style={{ margin: "30px 0", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <div style={{ display: "flex" }}>
          {getIcon}
          <span style={{ marginLeft: "10px", height: "40px" }}>Simulador</span>
        </div>
        <div>
          <ModalHelp></ModalHelp>
        </div>
      </div>
      <div>
        <label className="font13 extraBold" style={{ marginRight: '22px' }}>Tipos de Tela:</label>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tipoTela}
          label="Tipos de Tela"
          onChange={handleTipoTela}
        >
          <MenuItem value={0} selected>Selecione</MenuItem>
          <MenuItem value={5.5}>3,00m x 2,00m</MenuItem>
          <MenuItem value={13}>2,45m x 6,00m</MenuItem>
        </Select>
        <br />
        <label className="font13 extraBold" style={{ marginRight: '30px' }}>Area em m²:</label>
        <input type="number" onChange={handleArea} id="area" name="area" style={{ marginTop: '30px' }} className="font20 extraBold" />
        <br />
        <br />
        <p className="font30 extraBold" style={{ marginTop: '25px' }}>Quantitativo de Telas = {qtdTelas} unidades
          <FullButton width="10em" float="right" title="Calcular" action={handleClickQuery} />
        </p>
      </div>
    </Wrapper>)
      : (
        <Wrapper style={{ width: "1160px", height: "415px", display: "flex", justifyContent: "center", alignItems: "center" }} className="whiteBg radius8 shadow">
          <Fade
            in={query === 'progress'}
            style={{
              transitionDelay: query === 'progress' ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress size={100} />
          </Fade>
        </Wrapper>
      )
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 0px 30px 30px 30px;

  .MuiSelect-select {
    width: 256px;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 34px;
  }
`;
