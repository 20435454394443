import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Basis from "../../assets/img/clients/basis.png";
import Angra from "../../assets/img/clients/angra.png";
import Cfo from "../../assets/img/clients/cfo.png";
import Faz from "../../assets/img/clients/faz.png";
import Estrutura from "../../assets/img/clients/estrutura.png";
import Dimas from "../../assets/img/clients/dimas.png";
import Ferreira from "../../assets/img/clients/ferreira_antunes.jpg";
import Fontana from "../../assets/img/clients/fontana.jpg";
import Formaco from "../../assets/img/clients/formaco.jpg";
import GjArquitetos from "../../assets/img/clients/gj_arquitetos.jpg";
import Globo from "../../assets/img/clients/globo.jpg";
import GPinheiro from "../../assets/img/clients/gpinheiro.jpg";
import Hogar from "../../assets/img/clients/hogar.jpg";
import Jpf from "../../assets/img/clients/jpf.jpg";
import Leoncio from "../../assets/img/clients/leoncio.jpg";
import Pauta from "../../assets/img/clients/pauta.jpg";
import Pme from "../../assets/img/clients/pme.jpg";
import Rcl from "../../assets/img/clients/rcl.jpg";
import Sebold from "../../assets/img/clients/sebold.jpg";
import ShoppingItaguacu from "../../assets/img/clients/shopping_itaguacu.jpg";
import Sofplan from "../../assets/img/clients/softplan.jpg";
import Triplice from "../../assets/img/clients/triplice.jpg";
import Benveartt from "../../assets/img/clients/benveartt.png";
import Campos from "../../assets/img/clients/campos.png";
import Dreams from "../../assets/img/clients/dreams.jpg";
import Gdi from "../../assets/img/clients/gdi.png";
import Lumis from "../../assets/img/clients/lumis.png";
import Rdo from "../../assets/img/clients/rdo.png";
import Wkoerich from "../../assets/img/clients/wkoerich.png";


export default function Clientes() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1600,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Wrapper id="clientes">
      <div className="lightBg" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Clientes</h1>
          </HeaderInfo>
          <Slider {...settings}>
            <div>
              <img src={Gdi} style={{ width: "150px" }} alt="Gdi" className="radius6" />
            </div>
            <div>
              <img src={Wkoerich} style={{ width: "220px" }} alt="Wkoerich" className="radius6" />
            </div>
            <div>
              <img src={Lumis} style={{ width: "150px" }} alt="Lumis" className="radius6" />
            </div>
            <div>
              <img src={Rdo} style={{ width: "150px" }} alt="Rdo" className="radius6" />
            </div>
            <div>
              <img src={Basis} style={{ width: "150px" }} alt="Basis" className="radius6" />
            </div>
            <div>
              <img src={Estrutura} style={{ width: "90px" }} alt="Estrutura" className="radius6" />
            </div>
            <div>
              <img src={Angra} style={{ width: "75px" }} alt="Angra" className="radius6" />
            </div>
            <div>
              <img src={Cfo} style={{ width: "150px" }} alt="Cfo" className="radius6" />
            </div>
            <div>
              <img src={Faz} style={{ width: "150px" }} alt="Faz" className="radius6" />
            </div>
            <div>
              <img src={Dimas} style={{ width: "210px" }} alt="Faz" className="radius6" />
            </div>
            <div>
              <img src={Ferreira} style={{ width: "250px" }} alt="Dimas" className="radius6" />
            </div>
            <div>
              <img src={Fontana} style={{ width: "170px" }} alt="Fontana" className="radius6" />
            </div>
            <div>
              <img src={GjArquitetos} style={{ width: "80px" }} alt="GjArquitetos" className="radius6" />
            </div>
            <div>
              <img src={Globo} style={{ width: "140px" }} alt="Globo" className="radius6" />
            </div>
            <div>
              <img src={Formaco} style={{ width: "140px" }} alt="Formaco" className="radius6" />
            </div>
            <div>
              <img src={GPinheiro} style={{ width: "255px" }} alt="GPinheiro" className="radius6" />
            </div>
            <div>
              <img src={Hogar} style={{ width: "110px" }} alt="Hogar" className="radius6" />
            </div>
            <div>
              <img src={Jpf} style={{ width: "150px" }} alt="Jpf" className="radius6" />
            </div>
            <div>
              <img src={Leoncio} style={{ width: "200px" }} alt="Leoncio" className="radius6" />
            </div>
            <div>
              <img src={Pauta} style={{ width: "150px" }} alt="Pauta" className="radius6" />
            </div>
            <div>
              <img src={Pme} style={{ width: "200px" }} alt="Pme" className="radius6" />
            </div>
            <div>
              <img src={Rcl} style={{ width: "150px" }} alt="Rcl" className="radius6" />
            </div>
            <div>
              <img src={Sebold} style={{ width: "150px" }} alt="Sebold" className="radius6" />
            </div>
            <div>
              <img src={ShoppingItaguacu} style={{ width: "200px" }} alt="ShoppingItaguacu" className="radius6" />
            </div>
            <div>
              <img src={Sofplan} style={{ width: "200px" }} alt="Sofplan" className="radius6" />
            </div>
            <div>
              <img src={Triplice} style={{ width: "220px" }} alt="Triplice" className="radius6" />
            </div>
            <div>
              <img src={Benveartt} style={{ width: "150px" }} alt="Benveartt" className="radius6" />
            </div>
            <div>
              <img src={Campos} style={{ width: "200px" }} alt="Campos" className="radius6" />
            </div>
            <div>
              <img src={Dreams} style={{ width: "170px" }} alt="Dreams" className="radius6" />
            </div>
          </Slider>
        </div>
      </div>
    </Wrapper>


  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;