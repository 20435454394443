import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Empresa muito comprometida e de fácil acesso, sempre dão o melhor atendimento tanto no fechamento da obra quanto no pós obra, qualidade da execução indiscutível"
            author="RDO construtora"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Empresa perfeitamente gerenciada, agilidade e qualidade desde de o início do serviço até o fim"
            author="Engenheiro Ricardo - Construtora GPinheiro"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Clareza em todas as etapas do serviço e não ficam empurrando o serviço mais caro, e sim, o serviço mais ideal para o que preciso, por isso indico muito eles"
            author="Engenheira Silvya - Longwiew Empreendimentos"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Tive muitos problemas com outras empresas até achar esta, possuem uma excelente equipe desde da parte de vendas até a execução."
            author="Engenheiro Alexandre - Incorporadora FBV"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Tenho extrema confiança nessa empresa, o serviço prestado é de uma perfeita qualidade técnica, sempre disponíveis para sanar nossas dúvidas e auxiliando na melhor resolução de um problema encontrado"
            author="Miguel (empresário)" 
          />
        </LogoWrapper>
      </Slider>
    </Wrapper>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;

const Wrapper = styled.div`
.slick-prev::before,
.slick-next::before {
  color: gray;
}
`;