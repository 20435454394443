import * as React from "react";
import styled from "styled-components";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '90%',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '10px',
  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
  backgroundClip: 'padding-box',
  overflow: "auto"
};

export default function ProjectBox({ img, title, textModal, titleModal, action, items }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function Item(props)
{
    return (
        <Paper style={{ display: "flex", justifyContent: "center" }}>
          <img src={props.item.img} alt="" height="450px" width="450px" />
        </Paper>
    )
}

const MobileStyle = {
  width: '100%',
  height: '100%',
};

const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
        <img onClick={handleOpen} className="radius8" src={img} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13"></p>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, ...(isMobile ? MobileStyle : {}) }}>
          <span style={{ float: 'right', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleClose}>x</span>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontSize="2.25rem">
            {titleModal}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} lineHeight="2" >
            {textModal}
          </Typography>
          <br />
          <Carousel>
          {
            items.map((item, i) => <Item key={i} item={item} height="400px" width="400px"/>)
          }
        </Carousel>
        </Box>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    margin: 20px 0;
    width: 300px;
    height: 350px;
  }
  h3 {
    padding-bottom: 10px;
  }
  
  .teste {
    height: 100% !important;
    width: 100% !important;
    padding: 20px !important;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;